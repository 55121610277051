<template>
  <div class="pageWrapper">
    <head-new
        page_title="Ярмарка"
        :back-action="() => router.push({name: 'Home'})"
    />

    <AnotherUserProfileModalLoading
        :user="state.selectedUser"
        @close="() => {state.selectedUser = null}"
        style="position: relative;z-index: 1002"
    />

    <div class="pageWrapperOverflow">
      <RecycleScroller
          v-if="!state.isLoading && state.products.length"
          class="list main_container"
          :items="state.products"
          :item-size="152 + 12 + (((Math.min(width, 480) - 32) / 1.8)|0)"
          :buffer="(152 + 12) * 4 + (((Math.min(width, 480) - 32) / 1.8)|0)"
          key-field="id"
          @scroll-end="additionalLoading"
      >
        <template #before>
          <BlockSubscription
              :showAtStatuses="{[subscriptionStatuses.new]: true, [subscriptionStatuses.pending]: true}"
              :iconSize="40"
              newSubscriptionTitle="Загружай свои продукты с подпиской Younesis Premium"
              newSubscriptionText="Продвигай бесплатные продукты, находи клиентов и становись популярным экспертом на платформе"
              newSubscriptionButton="Подключить подписку"
              :newSubscriptionAction="openSubscription"
              pendingSubscriptionTitle="Загружай свои продукты с подпиской Younesis Premium"
              pendingSubscriptionText="Продвигай бесплатные продукты, находи клиентов и становись популярным экспертом на платформе"
              pendingSubscriptionButton="Подключить подписку"
              :pendingSubscriptionAction="openSubscription"
          />
          <div
              v-if="!(state.subscriptionStatus in {[subscriptionStatuses.new]: true, [subscriptionStatuses.pending]: true})"
              style="height: 24px"
          />
        </template>
        <template #after>
          <div style="height: 24px"/>
        </template>

        <template v-slot="{item: product, index, active}">
          <div style="padding-bottom: 12px">
            <div v-if="product.id > 0" class="product">
              <div
                  class="productInfo"
                  @click="state.isSelectedProduct = product"
                  style="cursor: pointer;"
              >
                <div
                    class="imgWrapper"
                    :style="{height: `${((Math.min(width, 480) - 32) / 1.8)|0}px`}"
                >
                  <div
                      v-if="product.image"
                      class="img"
                      :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(product.preview_image))}"
                  />
                  <div
                      v-else
                      class="img"
                      :style="{backgroundImage: urlToCssURL(require(`@/assets/images/product.png`))}"
                  />
                </div>

                <div style="overflow: hidden; flex-grow: 1; display: flex; flex-direction: column">
                  <Typography
                      variant="v16-600"
                      style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        display: block;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        text-overflow: ellipsis;
                        height: 60px;
                      "
                  >
                    {{ product.title }}
                  </Typography>
                  <!--                  <div class="title">-->
                  <!--                    {{ product.title }}-->
                  <!--                  </div>-->
                  <!--                  <div class="descriptionContainer" style="flex-grow: 1">-->
                  <!--                    <Typography-->
                  <!--                        :id="`text-${index}`"-->
                  <!--                        variant="v14-400"-->
                  <!--                        class="description"-->
                  <!--                        color="#A6A5A5"-->
                  <!--                        style="white-space: pre-wrap;"-->
                  <!--                        :style="{'-webkit-line-clamp': product['-webkit-line-clamp'] ?? calcLineClamp(product, index)}"-->
                  <!--                    >-->
                  <!--                      {{ product.description }}-->
                  <!--                    </Typography>-->
                  <!--                  </div>-->
                </div>

                <div class="bottom">
                  <Avatar
                      size="nano"
                      :image="product.created_by?.preview_avatar"
                  />
                  <div
                      style="flex-shrink: 1"
                  >
                    <Typography base="span" variant="body1" color="#D3D2D2">
                      {{ product.created_by.first_name }}
                    </Typography>
                    {{ ' ' }}
                    <Typography base="span" variant="body1" color="#D3D2D2">
                      {{ product.created_by.last_name }}
                    </Typography>
                  </div>
                  <div style="flex-grow: 1"/>

                  <AppButton
                      v-if="store.state.user.profile.id !== product.created_by.id"
                      size="small"
                      @click="state.isSelectedProduct = product"
                  >
                    Подробнее
                  </AppButton>
                </div>
              </div>
            </div>
            <div v-else class="product">
              <div class="productInfo">
                <Skeleton
                    height="116"
                    radius="8"
                    full-width
                />

                <div>
                  <Skeleton
                      width="180"
                      height="19"
                      :style="{marginBottom: '8px'}"
                  />
                  <Skeleton
                      variant="text"
                      width="260"
                      height="12"
                      style="margin-right: 12px"
                  />
                  <Skeleton
                      variant="text"
                      width="180"
                      height="12"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </RecycleScroller>

      <BottomDrawer
          :is-shown="state.isSelectedProduct"
          @close="state.isSelectedProduct = null"
          closeButtonOutside
      >
        <div
            v-if="state.isSelectedProduct"
            class="productInfo"
        >
          <div
              v-if="state.isSelectedProduct.preview_image"
              class="imgWrapper full"
              :style="{height: `${((Math.min(width, 480)) / 1.8)|0}px`}"
          >
            <div
                class="img"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.preview_image))}"
            />
          </div>

          <div>
            <div class="title">
              {{ state.isSelectedProduct.title }}
            </div>
            <Typography variant="body1" style="white-space: pre-wrap;">
              {{ state.isSelectedProduct.description }}
            </Typography>
          </div>

          <div class="bottom drawer">
            <Avatar
                size="tiny"
                :image="state.isSelectedProduct.created_by?.preview_avatar"
                @click="() => openUser(state.isSelectedProduct.created_by)"
            />
            <div
                style="flex-grow: 1; flex-shrink: 1;"
                @click="() => openUser(state.isSelectedProduct.created_by)"
            >
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ state.isSelectedProduct.created_by.first_name }}
              </Typography>
              {{ ' ' }}
              <Typography base="span" variant="body1" color="#D3D2D2">
                {{ state.isSelectedProduct.created_by.last_name }}
              </Typography>
            </div>

            <AppButton
                v-if="store.state.user.profile.id !== state.isSelectedProduct.created_by.id"
                size="small"
                @click="applyForProduct(state.isSelectedProduct.created_by, state.isSelectedProduct)"
            >
              Получить
            </AppButton>
          </div>
        </div>
      </BottomDrawer>
    </div>
  </div>
  <!--  </div>-->
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {router} from '@/router/router'
import {onBeforeMount, reactive, watch} from 'vue'
import {appAxios} from '@/axios'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Typography from '@/components/UI/Typography.vue'
import Avatar from '@/components/profile/Avatar.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {useRoute} from 'vue-router'
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import AnotherUserProfileModalLoading from "@/views/profile/AnotherUserProfileModalLoading.vue"
import store from "../../store/store.js"
import BlockSubscription from '@/views/subscriptions/BlockSubscription.vue'
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'

const route = useRoute()

const props = defineProps({
  productId: {
    type: String,
  }
})

const loadingCount = 10

import {useWindowSize} from '@vueuse/core'
const {width, height} = useWindowSize()

const state = reactive({
  isLoading: true,
  products: [],
  isSelectedProduct: null,
  selectedUser: null,
  count: 0,
  isAdditionalLoading: false,
  isAdditionalLoadingNeedMore: false,

  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  state.subscriptionStatus = subscriptions.at(0)?.status ?? subscriptionStatuses.new
})

function openUser(user) {
  if(!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  state.selectedUser = user
}

onBeforeMount(async () => {
  const res = await appAxios.products.fetch(loadingCount, 0)

  // const toUp = res.data.results.filter(c => c.id === 14)
  // const rest = res.data.results.filter(c => c.id !== 14)
  // state.products = [...toUp, ...rest]
  state.count = res.data.count
  state.products = res.data.results

  if (props.productId) {
    const found = res.data.find(p => String(p.id) === String(props.productId))
    if (found) {
      state.isSelectedProduct = found
    }
  }

  state.isLoading = false
})

async function additionalLoading() {
  if (state.count > state.products.length) {
    if (state.isAdditionalLoading) {
      state.isAdditionalLoadingNeedMore = true
      return
    }
    state.isAdditionalLoading = true

    state.products.push(...Array(loadingCount).fill(0).map((_, index) => ({
      id: -index - 1,
    })))

    const res = await appAxios.products.fetch(loadingCount, state.products.length - loadingCount)
    state.products = [...state.products.filter(i => i.id >= 0), ...res.data.results]

    state.isAdditionalLoading = false
    if (state.isAdditionalLoadingNeedMore) {
      state.isAdditionalLoadingNeedMore = false
      additionalLoading()
    }
  }
}

function startChat(userId) {
  router.push({name: 'MarketplaceProductUserChat', params: {userId, productId: state.isSelectedProduct.id}})
}

async function applyForProduct(user, product) {
  if(!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  const uid = createSendMessageByType.product({user, product})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function openSubscription() {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    returnName: 'MarketplaceProducts',
  }))
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}

// function calcLineClamp(product, index, animationFrame) {
//   const found = document.getElementById(`text-${index}`)
//   if (!found) {
//     setTimeout(() => calcLineClamp(product, index))
//     return
//   }
//   if (found.parentNode.clientHeight <= 0) {
//     if (animationFrame) {
//       return
//     }
//     requestAnimationFrame(() => calcLineClamp(product, index, true))
//     return
//   }
//   product['-webkit-line-clamp'] = Math.floor((found.parentNode.clientHeight) / 18)
// }

function calcHeight() {

}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  //min-height: calc(100vh);
  //max-height: calc(100vh);
  //min-height: calc(100svh);
  //max-height: calc(100svh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.pageWrapperOverflow {
  //margin-bottom: 80px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px !important;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 34px;
}

.list {
  height: calc(100vh - 56px - 80px);
}

.product {
  background: #222222;
  padding: 12px;
  border-radius: 16px;
  //height: 328px;
  //minimum 112px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 11px;

  .imgWrapper {
    margin: -12px -12px 0 -12px;
    width: calc(100% + 24px);
    border-radius: 8px 8px 0 0;
    background: #3B3B3B;
    display: flex;

    &.full {
      margin: -16px -16px 0 -16px;
      width: calc(100% + 32px);
    }

    .img {
      width: 100%;
      //height: 50px;
      flex-shrink: 0;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.bottom {
  display: flex;
  gap: 8px;
  padding-top: 16px;
  margin-top: 4px;
  border-top: 1px solid #494949;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }

  &.drawer {
    margin: 0 -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.descriptionContainer {
  overflow: hidden;
}

.description {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
