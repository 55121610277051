<template>
  <div class="pageWrapper">
    <head-new
        :back-action="() => router.push({
          name: state.SubscriptionPageSettings?.returnName ?? 'Profile',
          params: state.SubscriptionPageSettings?.params ?? {},
        })"
        title="Оформление подписки"
    />

    <div class="main_container pageWrapperOverflow">
      <Typography variant="v24-700" style="margin-top:16px; margin-bottom: 8px">
        {{ state.subscriptionPrice.title }}
      </Typography>

      <Typography variant="v20-700" style="margin-bottom: 24px; display: flex; gap: 8px; align-items: center">
        {{ rubFormat.format(state.subscriptionPrice.final_price) }} ₽
        <Typography
            v-if="state.subscriptionPrice.discounted_price && Number(state.subscriptionPrice.discounted_price) < Number(state.subscriptionPrice.price)"
            class="discountLabel"
            variant="v12-500"
        >
          {{
            `-${(((Number(state.subscriptionPrice.price) - Number(state.subscriptionPrice.discounted_price)) / Number(state.subscriptionPrice.price)) * 100) | 0}%`
          }}
        </Typography>
      </Typography>

      <div style="display: flex; margin-bottom: 24px">
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-start; gap: 16px">
          <div class="cell">
            Имя
          </div>
          <div class="cell">
            Номер телефона
          </div>
          <div class="cell">
            Электронная почта
          </div>
        </div>
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-end; gap: 16px">
          <div class="cell right">
            {{ state.profile.first_name }} {{ state.profile.last_name }}
          </div>
          <div class="cell right">
            {{ state.profile.phone }}
          </div>
          <div
              class="cell right"
              style="display: flex; gap: 6px"
          >
            {{ state.email }}
          </div>
        </div>
      </div>

      <Card style="margin-top: 24px; margin-bottom: 24px">
        <Typography variant="v12-400" color="#D3D2D2">
          Платеж находится в обработке, это может занять до 24 часов, после обработки платежа, подписка активируется, а
          чек придет на указанную почту
        </Typography>
      </Card>

      <AppButton
          v-if="state.paymentURL"
          full-width
          style="margin-bottom: 8px"
          size="large"
          @click="getSubscriptionLink"
      >
        Вернуться на экран оплаты
      </AppButton>

      <AppButton
          full-width
          variant="secondary"
          style="margin-bottom: 16px"
          size="large"
          @click="router.push({name: 'Home'})"
      >
        Вернуться на главный экран
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import Typography from "@/components/UI/Typography.vue"
import Card from "@/components/UI/Card.vue"
import store from "@/store/store.js"
import AppButton from '@/components/UI/AppButton.vue'
import {onBeforeMount, reactive, watch} from 'vue'
import {router} from '@/router/router.js'
import {paymentStatuses} from '@/configs/subscriptionStatuses.js'

const rubFormat = new Intl.NumberFormat('ru-RU', {})

const state = reactive({
  isReady: false,
  subscriptionPrice: null,
  SubscriptionPageSettings: null,
  email: null,
  profile: null,
  isSubscriptionLoading: false,
  paymentURL: null,
})

function getPaymentURL(subscriptions) {
  const subscription = subscriptions.at(0)
  if (subscription) {
    const found = subscription.payments.find(payment => payment.status === paymentStatuses.pending)
    if (!found || !found.payment_url) {
      state.paymentURL = null
      return
    }
    state.paymentURL = found.payment_url
    return
  }
  state.paymentURL = null
}

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  getPaymentURL(subscriptions)
})

onBeforeMount(async () => {
  getPaymentURL(store.state.user.profile.subscriptions)
  const raw = sessionStorage.getItem('SubscriptionPageSettings')
  if (!raw) {
    return router.push({name: 'SubscriptionTariffSelection'})
  }
  state.SubscriptionPageSettings = JSON.parse(raw)

  const subscription = store.state.user.profile.subscriptions.at(0)
  state.subscriptionPrice = subscription.price
  state.subscriptionPrice.final_price = state.subscriptionPrice.discounted_price ?? state.subscriptionPrice.price

  state.email = store.state.user.profile.email
  state.profile = store.state.user.profile
})

async function getSubscriptionLink() {
  window.location.href = state.paymentURL
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  min-height: calc(100svh - 60px);
  max-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
}

.discountLabel {
  background: #CF2E2E;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 20px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A6A5A5;

  &.right {
    font-weight: 500;
  }
}

</style>
