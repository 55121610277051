<template>
  <BottomDrawer
      :is-shown="props.shown && state.shownInfo"
      @close="close"
      title="Все включено 12 месяцев"
      hasTitleClose
  >
    <div
        v-if="props.shown && state.shownInfo"
    >
        <Typography
            variant="text2"
            color="#FFF"
            style="margin-bottom: 8px"
        >
          Ты в списке избранных! Чтобы получить доступ к обучению и расширенным возможностям платформы, оформи подписку на год
        </Typography>

      <div class="delimiter"/>

      <div style="margin-bottom: 16px">
        <Typography
            variant="subtitle"
            color="#FFF"
        >
          {{ rubFormat.format(state.subscription.price) }} ₽
        </Typography>
      </div>

      <AppButton
          full-width
          @click="() => {
            state.shownInfo = false;
            if(store.state.user.profile.subscriptions?.at(0)?.status === subscriptionStatuses.pending) {
              state.shownPending = true;
            } else {
              state.shownPayPage = true;
            }
          }"
          :loading="state.isSubscriptionLoading"
          :disabled="state.isSubscriptionLoading"
          style="padding: 0"
      >
        Получить доступ
      </AppButton>
    </div>
  </BottomDrawer>

  <ModalDialog
      :isShown="state.shownPending"
      :title="'Что-то пошло не так.'"
      :hasTitleClose="true" qqq
      @close="state.shownPending = false"
  >
    Пожалуйста, попробуйте оплатить позже. Если проблема сохранится, свяжитесь с нашей службой поддержки.
  </ModalDialog>

  <ModalDialog
      :isShown="state.shownError"
      :title="'shownError'"
      :hasTitleClose="true"
      @close="state.shownError = false"
  >
    shownError
  </ModalDialog>

  <div
      class="pageWrapper"
      v-if="state.shownPayPage"
  >
    <head-new
        title="Получить доступ"
        :back-action="() => close()"
    />
    <div class="main_container pageWrapperOverflow">
      <Typography
          style="margin-top: 24px"
          variant="h2"
          color="#FFF"
      >
        Все включено 12 месяцев
      </Typography>

      <!--      <Typography-->
      <!--          style="margin-top: 8px; margin-bottom: 16px"-->
      <!--          variant="v12-400"-->
      <!--          color="#FFF"-->
      <!--      >-->
      <!--        Вы попали в список избранных! Чтобы получить доступ к обучению и расширенным возможностям платформы,-->
      <!--        оформите подписку на год-->
      <!--      </Typography>-->

      <Typography
          style="margin-bottom: 24px;margin-top: 8px"
          variant="title"
          color="#FFF"
      >
        {{ rubFormat.format(state.subscription.price) }} ₽
      </Typography>

      <div style="display: flex; margin-bottom: 24px">
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-start; gap: 16px">
          <div class="cell">
            Имя
          </div>
          <div class="cell">
            Номер телефона
          </div>
          <div class="cell">
            Электронная почта
          </div>
        </div>
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-end; gap: 16px">
          <div class="cell right">
            {{ state.profile.first_name }} {{ state.profile.last_name }}
          </div>
          <div class="cell right">
            {{ state.profile.phone }}
          </div>
          <div
              class="cell right"
              style="display: flex; gap: 6px"
          >
            {{ values.email }}
            <AppButton
                variant="transparent"
                style="margin: -14px"
                @click="state.shownEmailChange = true"
            >
              <Icon
                  :icon="require(`@/assets/icons/white_20_pen.svg`)"
                  width="16"
                  height="16"
              />
            </AppButton>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; gap: 8px; margin-bottom: 24px">
        <Card
            v-for="pay in state.paymentList"
            style="padding: 12px; display: flex;gap: 8px;cursor: pointer"
            @click="selectPayment(pay)"
        >
          <div
              class="check"
              :class="{checked: pay === state.selectedPayment}"
          />
          <Typography
              variant="v14-500"
              color="#D3D2D2"
              style="padding: 2px 0"
          >
            <div
                v-if="pay.name  === 'sbp'"
                style="width: 24px; height: 16px; margin-right: 4px; display: inline-block"
            >
              <Icon
                  :icon="require(`@/assets/icons/_images/24_sbp.svg`)"
                  style="position: relative; top: -2px;"
              />
            </div>

            {{ pay.title }}

            <Typography
                v-if="pay.options && pay.options.length"
                style="margin-top: 4px"
                variant="v12-500"
                color="#FFFFFF"
            >
              <template v-if="state.selectedPayment === pay && state.selectedPaymentOption">
                {{ state.selectedPaymentOption.period_count }}
                {{ ' ' }}
                {{ optionDateMapping[state.selectedPaymentOption.period] }}
              </template>
              <template v-else>
                Выбрать срок
              </template>
            </Typography>
          </Typography>
        </Card>
      </div>

      <AppButton
          size="large"
          full-width
          @click="getSubscription"
          :loading="state.isSubscriptionLoading"
          :disabled="state.isSubscriptionLoading"
      >
        {{ state.selectedPaymentOption ? 'Оформить рассрочку' : 'Перейти к оплате' }}
      </AppButton>

      <Typography
          style="margin-top: 16px; margin-bottom: 24px"
          variant="v12-400"
          color="#A6A5A5"
          center
      >
        Нажимая кнопку и отправляя данные формы, Вы даёте согласие на обработку персональных данных и соглашаетесь с
        <a @click="state.shownPrivacyPolicy = true">политикой конфиденциальности</a> и пользовательским соглашением
      </Typography>
    </div>
  </div>

  <div
      class="pageWrapper"
      v-show="state.shownEmailChange"
  >
    <head-new
        title="Личные данные"
        :back-action="() => closeEmailEdit()"
    />
    <div
        class="main_container pageWrapperOverflow"
        style="padding-top: 24px; padding-bottom: 24px"
    >
      <FormTextInput
          name="email"
          label="Электронная почта"
      />
      <AppButton
          style="margin-top: 8px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        Сохранить
      </AppButton>
    </div>
  </div>

  <div
      class="pageWrapper"
      v-show="state.shownPrivacyPolicy"
  >
    <head-new
        title="Политика конфиденциальности"
        :back-action="() => state.shownPrivacyPolicy = false"
    />
    <div
        class="main_container pageWrapperOverflow"
        style="padding-top: 24px; padding-bottom: 24px"
    >
      <Typography
          variant="v15-400"
          color="#D3D2D2"
      >
        Политика конфиденциальности
      </Typography>
    </div>
  </div>

  <BottomDrawer
      :is-shown="state.shownOptionChange"
      @close="state.shownOptionChange = null"
      :title="state.shownOptionChange?.title"
      hasTitleClose
  >
    <div v-if="state.shownOptionChange">
      <Typography
          variant="v14-400"
          color="#A6A5A5"
          style="margin-bottom: 16px"
      >
        Выбери срок рассрочки
      </Typography>

      <div style="display: flex; flex-wrap: wrap; gap: 8px; margin-bottom: 16px">
        <AppButton
            v-for="option in state.shownOptionChange.options"
            variant="secondary"
            size="large"
            style="flex-basis: calc(33% - 6px)"
            @click="selectPayment(state.shownOptionChange, option)"
        >
          {{ option.period_count }}
          {{ ' ' }}
          {{ optionDateMapping[option.period] }}
        </AppButton>
      </div>
    </div>
  </BottomDrawer>

</template>

<script setup>
import HeadNew from "@/components/Head.vue";
import AppButton from "@/components/UI/AppButton.vue"
import BottomDrawer from "@/components/BottomDrawer.vue"
import Typography from "@/components/UI/Typography.vue"
import {appAxios} from "@/axios.js"
import {onBeforeMount, onMounted, reactive} from "vue"
import store from "@/store/store.js";
import {useForm} from "vee-validate";
import * as Yup from "yup";
import Card from "@/components/UI/Card.vue";
import Icon from "@/components/UI/Icon.vue";
import FormTextInput from "@/components/form/FormTextInput.vue";
import ModalDialog from "@/components/UI/ModalDialog.vue";
import {subscriptionStatuses} from "@/configs/subscriptionStatuses.js";

const optionDateMapping = {
  month: 'мес'
}

const rubFormat = new Intl.NumberFormat('ru-RU', {})

const emit = defineEmits(['close'])

const props = defineProps({
  shown: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  shownInfo: true,
  shownPayPage: false,
  shownEmailChange: false,
  shownOptionChange: false,
  shownPrivacyPolicy: false,

  shownPending: false,
  shownError: false,

  isSubscriptionLoading: false,
  subscription: null,
  profile: null,

  selectedPayment: null,
  selectedPaymentOption: null,

  paymentError: null,

  paymentList: [],
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, values, setFieldError} = useForm({
  validationSchema: Yup.object({
    email: Yup.string().trim().label('Электронная почта').required().email(),
  }),
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  closeEmailEdit()
})

function closeEmailEdit() {
  const {profile} = store.state.user
  if (profile.email) {
    setValues({
      email: store.state.user.profile.email,
    })
  }
  state.shownEmailChange = false
}

onMounted(async () => {
  const {profile} = store.state.user
  if (profile.email) {
    setValues({
      email: store.state.user.profile.email,
    })
  }
})

function close() {
  state.shownPayPage = false
  state.shownEmailChange = false
  state.shownOptionChange = false
  emit('close')
  state.shownInfo = true
}

onBeforeMount(async () => {
  state.profile = store.state.user.profile
  state.paymentList = store.state.user.directories.payment_type_list
  state.subscription = store.state.user.directories.subscription_price_list[0]
})

async function getSubscription() {
  if (!state.selectedPayment) {
    state.paymentError = 'Необходимо выбрать'
    return
  }

  state.isSubscriptionLoading = true
  try {
    const res = await appAxios.subscription.init(state.subscription.id, state.selectedPayment?.id, state.selectedPaymentOption?.id)
    if (!res.data.payment.payment_url) {
      throw new Error('bad payment link')
    }
    window.location.href = res.data.payment.payment_url
  } catch (error) {
    if (error.response?.data?.detail && error.response.data.detail === 'Payment init failed') {
      state.shownError = true
      return
    }
    throw error
  } finally {
    state.isSubscriptionLoading = false
  }
}

function selectPayment(payment, option) {
  state.shownOptionChange = null

  if (payment && option) {
    state.selectedPayment = payment
    state.selectedPaymentOption = option
    return;
  }

  if (payment.options.length === 0) {
    state.selectedPayment = payment
    return
  }

  state.shownOptionChange = payment
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100svh - $bottomMenuOffset);
  max-height: calc(100svh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pageWrapperOverflow {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A6A5A5;

  &.right {
    font-weight: 500;
  }
}


.check {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  flex-shrink: 0;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: 14px;
    height: 14px;
    margin: 3px;
    border-radius: 24px;
    border: 2px solid #D3D2D2;
  }

  &.checked {
    &:before {
      background: #CF2E2E;
      border: 2px solid #CF2E2E;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      display: block;
      width: 24px;
      height: 24px;
      background: url("@/assets/icons/white_24_checkBox.svg") no-repeat;
      background-size: contain;
    }
  }
}

a {
  text-decoration: underline;
}

</style>
