
export function urlToCssURL(url) {
  if(!url) {
    return ''
  }
  return `url("${url}")`
}

export function base64ToCssURL(url, type = 'svg+xml') {
  if(!url) {
    return ''
  }
  return `url("data:image/${type};base64,${url}")`
}
