<template>
  <div>
    <div v-if="educationId" class="modalWindow">
      <head-new
          :page_title="isCreate ? 'Добавить образование' : 'Редактировать образование'"
          :back-action="back"
      />
      <div class="main_container form">

        <FormSelect
            v-if="!educationType"
            name="education_type"
            label="Тип"
            :options="state.educationTypeList"
            :editable="false"
        />
        <FormTextInput
            v-else
            name="education_type_name"
            label="Тип"
            disabled
        />

        <FormSelect
            v-show="educationFieldsMeta[values.institution_type]?.institution_type"
            name="institution_type"
            :label="educationFieldsMeta[values.institution_type]?.institution_type?.label ?? ''"
            :options="[]"
            :editable="false"
        />

        <FormTextInput
            v-show="educationFieldsMeta[values.education_type]?.title"
            name="title"
            :label="educationFieldsMeta[values.education_type]?.title?.label ?? ''"
        />

        <FormTextInput
            v-show="educationFieldsMeta[values.education_type]?.degree"
            name="degree"
            :label="educationFieldsMeta[values.education_type]?.degree?.label ?? ''"
        />

        <FormYear
            v-show="educationFieldsMeta[values.education_type]?.ended_at"
            name="ended_at"
            :label="educationFieldsMeta[values.education_type]?.ended_at?.label ?? ''"
            :text-info="educationFieldsMeta[values.education_type]?.ended_at?.textInfo ?? ''"
        />

        <FormImageList
            v-show="educationFieldsMeta[values.education_type]?.images"
            :label="educationFieldsMeta[values.education_type]?.images?.label ?? ''"
            name="images"
            :aspectRatio="null"
        />

        <div class="buttons">
          <appButton
              full-width
              class="submitButton"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              @click="onSubmit"
          >
            Сохранить
          </appButton>

          <appButton
              full-width
              class="submitButton"
              :disabled="isSubmitting"
              variant="secondary"
              @click="() => isCreate ? back() : onDelete()"
          >
            {{ state.isCreate || educationsCount <= 1 ? 'Отменить' : 'Удалить' }}
          </appButton>
        </div>

        <BottomDrawer
            :is-shown="state.isDelete"
            title="Удаление данных"
            @close="state.isDelete = null"
        >
          <Typography variant="body1" color="#D3D2D2">
            Ты действительно хочешь удалить данные об образовании?
          </Typography>

          <div class="modalButtons">
            <appButton
                variant="secondary"
                :disabled="state.isDeleting"
                @click="state.isDelete = null"
            >
              Оставить
            </appButton>

            <appButton
                variant="redSecondary"
                @click="deleteEducation(state.isDelete)"
                :disabled="state.isDeleting"
                :loading="state.isDeleting"
                color="#FF303A"
            >
              Удалить
            </appButton>
          </div>
        </BottomDrawer>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {computed, nextTick, onMounted, reactive, toRaw, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import {useRoute} from 'vue-router'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import FormImageList from '@/components/form/FormImageList.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import {educationFieldsMeta, educationTypeToPlural, getEducationName} from '@/configs/educationType'
import FormYear from '@/components/form/FormYear.vue'
import moment from 'moment/moment'
import {appAxios} from "@/axios.js";
import {useModalScrollingFix} from "@/hooks/useModalScrollingFix.js";
import {useModalHomeLayoutFix} from "@/hooks/useModalHomeLayoutFix.js";

const props = defineProps({
  educationId: {
    type: String,
  },
  educationType: {
    type: String,
  },
})

const route = useRoute()

const modalScrollingFix = useModalScrollingFix()
const modalHomeLayoutFix = useModalHomeLayoutFix()

const emit = defineEmits(['close'])

const isCreate = computed(() => props.educationId === 'new')

const state = reactive({
  isLoading: true,
  isDelete: null,
  isDeleting: false,
  educationTypeList: [],
})

watch(() => props.educationId, (event) => {
  if (props.educationId) {
    modalScrollingFix.open()
    modalHomeLayoutFix.open()

    const educationLimits = store.state.user.directories.education_type_limit
    const education = store.state.user.profile?.education?.at(0) ?? {}

    state.educationTypeList = store.state.user.directories.education_type_list.map(item => {
      return {
        value: item.name,
        label: `${item.description} — ${education[educationTypeToPlural(item.name)]?.length ?? 0}/${educationLimits[item.name]}`,
        disabled: education[educationTypeToPlural(item.name)]?.length >= educationLimits[item.name],
      }
    })

    if (props.educationId && props.educationType) {
      const education = store.state.user.profile?.education?.at(0)
      const found = education[educationTypeToPlural(props.educationType)].find(item => item.id.toString() === props.educationId.toString())
      if (found) {
        requestAnimationFrame(() => {
          setValues({
            ...toRaw(found),
            education_type: props.educationType,
            education_type_name: getEducationName(props.educationType),
            ended_at: (moment(found.ended_at, "YYYY-MM-DD")).format('YYYY')
          })
        })
      }
    } else {
      // setFieldValue('education_type', state.educationTypeList[0].value)
      nextTick(() => {
        setFieldValue('images', [])
      })
    }

  } else {
    modalScrollingFix.close()
    modalHomeLayoutFix.close()
  }
})

function back() {
  emit('close')
}

const {handleSubmit, values, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    education_type: Yup.string().trim().label('Тип образования').required(),
    title: Yup.string()
        .when('education_type', {
          is: val => val === 'institution', then: () => Yup.string().trim().label('Учебное заведение').required(),
        })
        .when('education_type', {
          is: val => val === 'course', then: () => Yup.string().trim().label('Название').required(),
        })
        .when('education_type', {
          is: val => val === 'community', then: () => Yup.string().trim().label('Название').required(),
        }),
    images: Yup.array(),
  }),
});

onMounted(() => {
  const educationLimits = store.state.user.directories.education_type_limit
  const education = store.state.user.profile?.education?.at(0) ?? {}

  state.educationTypeList = store.state.user.directories.education_type_list.map(item => {
    return {
      value: item.name,
      label: `${item.description} — ${education[educationTypeToPlural(item.name)]?.length ?? 0}/${educationLimits[item.name]}`,
      disabled: education[educationTypeToPlural(item.name)]?.length >= educationLimits[item.name],
    }
  })

  // if (props.educationId && props.educationType) {
  //   const education = store.state.user.profile?.education?.at(0)
  //   const found = education[educationTypeToPlural(props.educationType)].find(item => item.id.toString() === props.educationId.toString())
  //   setValues({
  //     images: [],
  //     ...toRaw(found),
  //     education_type: props.educationType,
  //     education_type_name: getEducationName(props.educationType),
  //     ended_at: (moment(found.ended_at, "YYYY-MM-DD")).format('YYYY')
  //   })
  // } else {
  //   // setFieldValue('education_type', state.educationTypeList[0].value)
  //   setFieldValue('images', [])
  //   nextTick(() => {
  //     setFieldValue('images', [])
  //   })
  // }
})

// watch(props.educationId, () => {
//   const educationLimits = store.state.user.directories.education_type_limit
//   const education = store.state.user.profile?.education?.at(0)
//
//   state.educationTypeList = store.state.user.directories.education_type_list.map(item => {
//     return {
//       value: item.name,
//       label: `${item.description} — ${education[educationTypeToPlural(item.name)].length}/${ educationLimits[item.name]}`,
//       disabled: education[educationTypeToPlural(item.name)].length >= educationLimits[item.name],
//     }
//   })
// })

const onDelete = async function () {
  if (isCreate.value) {
    return router.push({name: 'EditEducations'});
  } else {
    state.isDelete = {
      item: {id: props.educationId},
      educationType: props.educationType,
    }
  }
}

const onSubmit = handleSubmit(async formValues => {
  const {education_type_name, ...values} = formValues

  if (values.ended_at) {
    values.ended_at = (moment(values.ended_at, "YYYY")).format("YYYY-MM-DD")
  }

  if (isCreate.value) {
    const res = await appAxios.educations.create(values)
    await store.commit('user/setProfile', {
      education: [res.data],
    })
  } else {
    const education = store.state.user.profile?.education?.at(0)
    const found = education[educationTypeToPlural(values.education_type)].find(item => item.id.toString() === props.educationId.toString())

    const res = await appAxios.educations.update({
      ...toRaw(found),
      ...toRaw(values),
    }, education.id)

    await store.commit('user/setProfile', {
      education: [res.data],
    })
  }
  back()
})

async function deleteEducation({item, educationType}) {
  state.isDeleting = true
  try {
    const res = await appAxios.educations.remove(item, educationType)
    await store.commit('user/setProfile', {
      education: [res.data],
    })
    state.isDelete = null
  } catch (e) {
    console.log('e', e)
  }
  state.isDeleting = false
  back()
}


</script>

<style lang="scss" scoped>

.modalWindow {
  position: fixed;
  z-index: 1001;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100svh);
  max-height: calc(100svh);
  overflow: auto;
  padding-bottom: 32px;
}


.form {
  margin-top: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
    flex-basis: 100px;
  }
}

</style>
