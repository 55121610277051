<template>
  <head-new
      page_title="О себе"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProfile'})"
  />
  <div class="main_container form">
    <form @submit.prevent="onSubmit">

      <Card class="info">
        <Typography variant="body1">
          Главные факты, о которых стоит знать другим
        </Typography>
      </Card>

      <FormTextArea
          class="textArea"
          label="О себе"
          name="about_me"
          :text-info="`${values.about_me?.length}/1000 символов`"
          :rows="6"
      />

      <appButton
          class="submitButton"
          full-width
          :loading="isSubmitting"
          :disabled="isSubmitting"
          type="submit"
      >
        Сохранить
      </appButton>
    </form>
  </div>

</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {Form, useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onMounted, reactive} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import FormTextArea from '@/components/form/FormTextArea.vue'
import {useRoute} from "vue-router";

const route = useRoute()

const state = reactive({})

const {handleSubmit, values, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    about_me: Yup.string().trim().label('Описание профиля').required().max(1000),
  }),
});

onMounted(() => {
  const {profile} = store.state.user
  if(profile.about_me) {
    setValues({
      about_me: profile.about_me,
    })
  }
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'EditProfile'})
})

</script>

<style lang="scss" scoped>
.info {
  margin-top: 16px;
  margin-bottom: 24px;
}

.submitButton {
  margin-top: 8px;
}
</style>
