<template>
  <PinnedTopTemplate>
    <template  v-slot:header>
      <head-new
          page_title="Визитница"
          :rightAction="state.tab !== 'contacts' || !state.isMounted || targetIsVisible || state.isLoading ? null : openSearch"
          :rightIcon="require(`@/assets/icons/white_24_search.svg`)"
      />
      <div class="main_container tabs">
        <button
            :class="{ selected: state.tab === 'contacts' }"
            @click="router.push({name: 'CardHolder', query: {tab: 'contacts'}})"
        >
          Карточки
        </button>
        <button
            :class="{ selected: state.tab === 'match'}"
            @click="router.push({name: 'CardHolder', query: {tab: 'match'}})"
        >
          Мэтчи
          <span class="redCircle" v-if="matchCount">
          {{ matchCount }}
        </span>
        </button>
        <button
            :class="{ selected: state.tab === 'interests'}"
            @click="router.push({name: 'CardHolder', query: {tab: 'interests'}})"
        >
          Интересы
          <span class="redCircle" v-if="interestCount">
          {{ interestCount }}
        </span>
        </button>
      </div>
    </template >

    <div class="card_holder">
      <bottom-drawer
          v-if="!state.isLoading"
          :isShown="state.userContactShown"
          title="Удаление из визитницы"
          @close="state.userContactShown = false"
      >
        <template v-if="state.userContactShown">
          <Typography variant="v14-600" color="#D3D2D2">
            Ты действительно хочешь удалить
            {{ state.userContactShown.contact_user.first_name }} {{ state.userContactShown.contact_user.last_name }}
            из визитницы?
          </Typography>

          <div style="display: flex; gap: 4px; margin: 16px 0">
            <appButton
                full-width
                class="submitButton"
                variant="secondary"
                :disabled="state.isDeleting"
                @click="state.userContactShown = false"
            >
              Отменить
            </appButton>
            <appButton
                full-width
                class="submitButton"
                variant="secondary"
                color="#FF303A"
                :disabled="state.isDeleting"
                :loading="state.isDeleting"
                @click="() => evaluated(0, state.userContactShown)"
            >
              Удалить
            </appButton>
          </div>
        </template>
      </bottom-drawer>

      <template v-if="state.tab === 'contacts'">
        <RecycleScroller
            v-if="state.contactList.length"
            class="main_container holder_in"
            :items="state.contactList"
            :item-size="264"
            key-field="id"
            :buffer="264"
            @scroll-end="e => null"
        >
          <template #before>
            <div class="search" ref="refSearch">
              <FormTextInputSearch
                  leftIcon="`@/assets/icons/gray_24_search.svg`"
                  name="searchQuery"
                  placeholder="Ключевые слова"
                  fullWidth
                  onlyInput
                  @focus="openSearch"
                  @options="openSearch({filter: true})"
              />
            </div>
          </template>

          <template #after>
            <div style="height: 16px"/>
          </template>

          <template v-slot="{item}">
            <UserCard
                :key="item.id"
                :data="item.contact_user"
                :userContact="item"
                style="margin-bottom: 8px"
                :profileClick="() => {router.push({name: 'CardHolderUserProfile', params: {userId: item.contact_user.id}})}"
                @reEvaluate="contact => state.userContactShown = contact"
            />
          </template>
        </RecycleScroller>
      </template>

      <template v-if="state.tab === 'match'">
        <RecycleScroller
            v-if="state.matchList.length"
            class="main_container holder_in"
            :items="state.matchList"
            :item-size="264"
            key-field="id"
            :buffer="264"
            @scroll-end="e => null"
        >
          <template #before>
            <Typography variant="v12-400" color="#A6A5A5" center style="margin: 16px 0">
              Вы друг у друга в визитнице
            </Typography>
          </template>

          <template #after>
            <div style="height: 16px"/>
          </template>

          <template v-slot="{item}">
            <UserCard
                :key="item.id"
                :data="item.user"
                :userContact="item"
                style="margin-bottom: 8px"
                :profileClick="() => {router.push({name: 'CardHolderUserProfile', params: {userId: item.user.id}})}"
                @reEvaluate="contact => state.userContactShown = contact"
                :hideActions="true"
                :hideLocation="true"
                :lineClamp="item.checked_at ? 6 : 3"
            >
              <AppButton
                  v-if="!item.checked_at"
                  :loading="item.isChecking"
                  :disabled="item.isChecking"
                  full-width
                  size="small"
                  variant="green"
                  color="#20B537"
                  @click="(e) => {e.stopPropagation(); checkContact(item, 'match')}"
              >
                Отметить просмотренным
              </AppButton>
            </UserCard>
          </template>
        </RecycleScroller>
      </template>

      <template v-if="state.tab === 'interests'">
        <RecycleScroller
            v-if="state.interestList.length"
            class="main_container holder_in"
            :items="state.interestList"
            :item-size="264"
            key-field="id"
            :buffer="264"
            @scroll-end="e => null"
        >
          <template #before>
            <Typography variant="v12-400" color="#A6A5A5" center style="margin: 16px 0">
              Тебя добавили в визитницу
            </Typography>
          </template>

          <template #after>
            <div style="height: 16px"/>
          </template>

          <template v-slot="{item}">
            <UserCard
                :key="item.id"
                :data="item.user"
                :userContact="item"
                style="margin-bottom: 8px"
                :profileClick="() => {router.push({name: 'CardHolderUserProfile', params: {userId: item.user.id}})}"
                @reEvaluate="contact => state.userContactShown = contact"
                :hideActions="true"
                :hideLocation="true"
                :lineClamp="item.checked_at ? 6 : 3"
            >
              <div style="display: flex; gap: 8px; width: 100%">
                <AppButton
                    v-if="!item.checked_at"
                    :loading="item.isChecking"
                    :disabled="item.isChecking"
                    size="small"
                    style="padding: 0 32px"
                    variant="secondary"
                    @click="(e) => {e.stopPropagation(); checkContact(item, 'interest')}"
                >
                  Пропустить
                </AppButton>
                <AppButton
                    v-if="!item.checked_at"
                    :loading="item.isLoading"
                    :disabled="item.isLoading"
                    full-width
                    size="small"
                    variant="green"
                    color="#20B537"
                    style="flex-grow: 1;"
                    @click="(e) => {e.stopPropagation(); addToContact(item, 'interest')}"
                >
                  <Icon
                      :icon="require(`@/assets/icons/green_20_plus.svg`)"
                  />
                  Добавить в ответ
                </AppButton>
              </div>
            </UserCard>
          </template>
        </RecycleScroller>
      </template>

      <div class="main_container holder_in">
        <template v-if="!state.isLoading">
          <div
              v-if="state.contactList.length === 0 && state.tab === 'contacts'"
              class="listIsEmpty"
          >
            <div style="flex-grow: 1"/>
            <div class="userEmptyCards">
              <div class="userFakeCard"/>
              <div class="userFakeCard"/>
              <UserCard
                  :is-loading="true"
                  :data="null"
                  hideActions
                  variant="small"
              />
            </div>

            <Typography variant="subtitle" center style="padding: 0 12px">
              Визитница пуста
            </Typography>

            <Typography variant="body1" center color="#D3D2D2" style="padding: 0 12px">
              Начинай нетворкинг, добавляй пользователей и создавай окружение своих людей!
            </Typography>

            <AppButton
                style="margin-top: 24px"
                @click="router.push({name: 'CardHolderSocialBrowsing'})"
            >
              Нетворкать
            </AppButton>
            <div style="flex-grow: 1"/>
          </div>

          <div
              v-if="state.matchList.length === 0 && state.tab === 'match'"
              class="listIsEmpty"
          >
            <div style="flex-grow: 1"/>

            <Typography variant="subtitle" center style="padding: 0 12px">
              У тебя пока нет ни одного мэтча
            </Typography>

            <Typography variant="body1" center color="#D3D2D2" style="padding: 0 12px">
              Используй нетворкинг, взаимодействуй с другими экспертами и ищи свой идеальный бизнес-мэтч
            </Typography>

            <AppButton
                style="margin-top: 24px"
                @click="router.push({name: 'CardHolderSocialBrowsing'})"
            >
              Перейти в нетворкинг
            </AppButton>
            <div style="flex-grow: 1.5"/>
          </div>

          <div
              v-if="state.interestList.length === 0 && state.tab === 'interests'"
              class="listIsEmpty"
          >
            <div style="flex-grow: 1"/>

            <Typography variant="body1" center color="#D3D2D2" style="padding: 0 36px">
              Скоро здесь появятся эксперты, которые добавили тебя в визитницу
            </Typography>

            <div style="flex-grow: 1.5"/>
          </div>
        </template>
        <template v-if="state.isLoading">
          <div class="search">
            <Skeleton
                height="48"
                radius="12"
                full-width
            />
          </div>
          <div style="display: flex; flex-direction: column; gap: 16px;">
            <UserCard
                v-if="state.isLoading"
                v-for="i in 5"
                :data="{}"
                :isLoading="true"
                :userContact="true"
            />
          </div>
        </template>
      </div>
    </div>
  </PinnedTopTemplate>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {computed, onBeforeMount, onMounted, reactive, ref} from 'vue'
import {appAxios} from '@/axios'
import {router} from '@/router/router'
import UserCard from '@/components/UserCard.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useElementVisibility} from '@vueuse/core'
import FormTextInputSearch from "@/components/form/FormTextInputSearch.vue"
import Skeleton from "@/components/UI/Skeleton.vue"
import {useRoute} from "vue-router"
import Icon from "@/components/UI/Icon.vue"
import store from "@/store/store.js"
import {indexedStore} from "@/indexedDB/indexedStore.js"
import AnotherUserProfileModalLoading from '@/views/profile/AnotherUserProfileModalLoading.vue'
import PinnedTopTemplate from '@/layouts/PinnedTopTemplate.vue'

async function getCache(type) {
  const cache = await indexedStore.slices.base.get(`cardHolderCache-${type}`)
  if (!cache) {
    return null
  }
  if (cache.userId !== store.state.user.profile.id) {
    return null
  }
  return cache
}

async function setCache(type, data) {
  const cache = await getCache(type)

  if (cache) {
    const usedIds = {}
    data.forEach(chat => usedIds[chat.id] = true)

    cache.data.forEach(chat => {
      if (!usedIds[chat.id]) {
        data.push(chat)
      }
    })
  }

  if(type !== 'contacts') {
    data = data
        .sort((a, b) => new Date(a.checked_at) - new Date(b.checked_at))
  }

  if (data.length) {
    const newCache = {
      userId: store.state.user.profile.id,
      lastUpdatedAtDate: data.at(0).updated_at,
      data,
    }
    await indexedStore.slices.base.set(`cardHolderCache-${type}`, newCache)

    return data
  }
  return []
}

const refSearch = ref(null)
const targetIsVisible = useElementVisibility(refSearch)

const route = useRoute()

const state = reactive({
  isLoading: true,
  isMounted: false,
  userContactShown: false,

  tab: route.query.tab ?? 'contacts',

  user: null,
  contactList: [],
  matchList: [],
  interestList: [],

  responseMatchCount: 0,
  responseInterestCount: 0,

  data: null,
  isDeleting: false,
})

const matchCount = computed(() => {
  const count = store.state.user?.profile?.unchecked_match_count

  if (count !== state.responseMatchCount && !state.isLoading && state.tab === 'match') {
    void async function () {
      await loading()
    }()
  }

  if (count) {
    const num = Number(count)
    if (num > 99) {
      return `99+`
    }
    return num
  }
  return null
})

const interestCount = computed(() => {
  const count = store.state.user?.profile?.unchecked_interest_count

  if (count !== state.responseInterestCount && !state.isLoading && state.tab === 'interests') {
    void async function () {
      await loading()
    }()
  }

  if (count) {
    const num = Number(count)
    if (num > 99) {
      return `99+`
    }
    return num
  }
  return null
})

async function loading() {
  if (state.tab === 'contacts') {
    const cache = await getCache('contacts')
    let res = null
    let axiosRes = null

    if (cache) {
      const {response, axiosResponse} = await appAxios.myContacts.fetchContacts(
          (new Date(cache.lastUpdatedAtDate)).toISOString(),
      )
      res = response
      axiosRes = axiosResponse
    } else {
      const {response, axiosResponse} = await appAxios.myContacts.fetchContacts()
      res = response
      axiosRes = axiosResponse
    }
    state.contactList = await setCache('contacts', res.data.contacts)
    axiosRes.then(async r => {
      state.contactList = await setCache('contacts', r.data.contacts)
    })
  }
  if (state.tab === 'match') {
    const cache = await getCache('match')
    let res = null
    let axiosRes = null

    if (cache) {
      const {response, axiosResponse} = await appAxios.myContacts.fetchMatches(
          (new Date(cache.lastUpdatedAtDate)).toISOString(),
      )
        res = response
        axiosRes = axiosResponse
    } else {
      const {response, axiosResponse} = await appAxios.myContacts.fetchMatches()
      res = response
      axiosRes = axiosResponse
    }
    state.matchList = await setCache('match', res.data.contacts)
    state.responseMatchCount = res.data.unchecked_count

    axiosRes.then(async r => {
      state.matchList = await setCache('match', r.data.contacts)
      state.responseMatchCount = r.data.unchecked_count
    })
  }
  if (state.tab === 'interests') {
    const cache = await getCache('interests')
    let res = null
    let axiosRes = null

    if (cache) {
      const {response, axiosResponse} = await appAxios.myContacts.fetchInterests(
          (new Date(cache.lastUpdatedAtDate)).toISOString(),
      )
      res = response
      axiosRes = axiosResponse
    } else {
      const {response, axiosResponse} = await appAxios.myContacts.fetchInterests()
      res = response
      axiosRes = axiosResponse
    }
    state.interestList = await setCache('interests', res.data.contacts)
    state.responseInterestCount = res.data.unchecked_count

    axiosRes.then(async r => {
      state.interestList = await setCache('interests', r.data.contacts)
      state.responseInterestCount = r.data.unchecked_count
    })
  }
}

onMounted(() => {
  setTimeout(() => {
    state.isMounted = true
  })
})

onBeforeMount(async () => {
  state.isLoading = true
  await loading()
  state.isLoading = false
})

function openSearch(params = {}) {
  const {filter} = params
  router.push({name: 'CardHolderSearch', params: {filter}})
}

async function evaluated(rate, userContact) {
  if (rate === 0) {
    state.isDeleting = true
    await appAxios.myContacts.removeContact(userContact.id, userContact.contact_user.id)
    state.contactList = state.contactList.filter(c => c.id !== userContact.id)
    state.isDeleting = false
  } else {
    const res = await appAxios.myContacts.updateContact(userContact.id, rate)
    const newUserContact = res.data
    const foundIndex = state.contactList.findIndex(c => c.id === newUserContact.id)
    if (foundIndex > -1) {
      state.contactList[foundIndex] = newUserContact
    }
  }
  state.userContactShown = false
}

async function checkContact(item, type) {
  item.isChecking = true
  const res = await appAxios.myContacts.markChecked(item.id, type)
  item.checked_at = Date.now()
  item.isChecking = false
}

async function addToContact(item, type) {
  item.isLoading = true

  let id = null
  switch (type) {
    case 'interest':
      id = item.user.id
      break
    case 'contacts':
    default:
      id = item.contact_user.id
      break
  }

  await Promise.all([
    appAxios.myContacts.addContact(id, 5),
  ])
  item.checked_at = Date.now()
  item.isLoading = false
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.holder_in {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.card_holder {
  //margin-bottom: -$homeLayoutPaddingBottom;
  //min-height: calc(100vh - $bottomMenuOffset);
  //min-height: calc(100svh - $bottomMenuOffset);
  //max-height: calc(100vh - $bottomMenuOffset);
  //max-height: calc(100svh - $bottomMenuOffset);
  //display: flex;
  //flex-direction: column;
  //overflow: hidden;
  overflow: auto;
  flex-direction: column;
  display: flex;
  flex-shrink: 1 !important;
}

.holder_modal_active {
  opacity: 1;
  z-index: 101;

  .holder_modal_in {
    transform: translateY(0);
  }
}

.listIsEmpty {
  margin-top: 64px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.userFakeCard {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #FFFFFF14;
  background: #222222;
  height: 220px;
}

.userEmptyCards {
  margin-bottom: 32px;
  position: relative;

  & > *:nth-child(1) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -48px;
    transform: scale(0.8);
    z-index: 1;
    background: #1B1B1B;
  }

  & > *:nth-child(2) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -24px;
    transform: scale(0.9);
    z-index: 2;
    background: #1F1F1F;
  }

  & > *:nth-child(3) {
    position: relative;
    z-index: 3;
  }
}

.search {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

.tabs {
  display: flex;
  padding: 0;

  button {
    width: 100%;
    background: #171717;
    height: 42px;
    font-size: 14px;
    border-bottom: 2px #494949 solid;
    color: #FFFFFF;

    &.disabled {
      color: #A6A5A5;
    }

    &.selected {
      border-bottom: 2px #CF2E2E solid;
    }
  }
}

.redCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: #CF2E2E;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

</style>
