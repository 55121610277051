import {pwaUtils} from '@/utils/pwaUtils.js'
import {appType, appTypes} from '@/appType/appType.js'
import {reactive} from 'vue'

const iosTelegramFix = {
  isActive: pwaUtils.isAppleMobile() && appType === appTypes.TELEGRAM,
  // isActive: true,
}

const state = reactive({
  appElement: null,
})


function onFocus(e) {
  const tags = ['INPUT', 'TEXTAREA']
  const focused = e.target

  if(!focused || !focused?.dataset) {
    return
  }

  if(!focused.dataset.iostelegramfixbottom && !focused.dataset.iostelegramfixtop) {
    return
  }

  if (focused && focused && tags.includes(focused.tagName)) {
    const el = document.querySelector('#app')
    if (focused.dataset.iostelegramfixtop) {
      el.style.top = `${focused.dataset.iostelegramfixtop}px`
    }
    if (focused.dataset.iostelegramfixbottom) {
      el.style.bottom = `${focused.dataset.iostelegramfixbottom}px`
    }
  }
}

function onBlur(e) {
  const tags = ['INPUT', 'TEXTAREA']
  const focused = e.target
  if (focused && focused && tags.includes(focused.tagName)) {
    const el = document.querySelector('#app')
    el.style.top = null
    el.style.bottom = null
  }
}

// onMounted(() => {
if(iosTelegramFix.isActive) {
  state.appElement = document.querySelector('#app')
  state.appElement.style.position = 'relative'
  addEventListener("focus", onFocus, true)
  addEventListener("blur", onBlur, true)
}

// })
//
// onUnmounted(() => {
//   removeEventListener("focus", onFocus);
//   removeEventListener("blur", onBlur);
// })

export {iosTelegramFix}
