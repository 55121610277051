import {BaseIndexedSlice} from "@/indexedDB/BaseIndexedSlice.js";

const indexedStoreVersion = '1'
const indexedStoreVersionTag = 'indexedStoreVersion'

export const indexedStore = {
  currentUserID: localStorage.getItem('indexedStoreUserID'),
  version: localStorage.getItem(indexedStoreVersionTag),

  slices: {
    base: new BaseIndexedSlice('base'),
    myCourses: new BaseIndexedSlice('courses'),
    courses: new BaseIndexedSlice('courses'),
    chats: new BaseIndexedSlice('chats'),
  },

  async clear() {
    const slices = this.slices
    await Promise.all(Object.keys(slices).map(key => {
      return slices[key].clear()
    }))
  },

  async init(id) {
    const slices = this.slices
    const userID = id.toString()
    localStorage.setItem('indexedStoreUserID', userID)

    if (this.currentUserID === userID && this.version === indexedStoreVersion) {
      await Promise.all(Object.keys(slices).map(key => {
        return slices[key].init()
      }))
      return
    }

    if(this.version !== indexedStoreVersion) {
      console.log('wrong version')
      localStorage.setItem(indexedStoreVersionTag, indexedStoreVersion)
    }

    await Promise.all(Object.keys(slices).map(key => {
      return slices[key].clear()
    }))
  },
}

window.indexedStore = indexedStore
