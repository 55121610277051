import {init} from '@telegram-apps/sdk'
import * as telegramSDK from '@telegram-apps/sdk'

window.telegramSDK = telegramSDK

const appTypes = {
  WEB: 'web',
  TELEGRAM: 'telegram',
}

let appType = appTypes.WEB

try {
  init()
  appType = appTypes.TELEGRAM

  if(telegramSDK.swipeBehavior.isSupported() && telegramSDK.swipeBehavior.mount()) {
    telegramSDK.swipeBehavior.disableVertical()
  }

  if(telegramSDK.backButton.isSupported() && telegramSDK.backButton.mount()) {
    telegramSDK.backButton.show()
  }
} catch (e) {
  // not Telegram
}


export {appTypes, appType}
