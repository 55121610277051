<template>
  <div
      class="textInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: fullWidth }"
  >
    <label :for="name">
      {{ label }}
    </label>

    <ImageCropper
        :aspectRatio="aspectRatio"
        :files="state.filesToCrop"
        @onCrop="onCrop"
        @onClose="state.filesToCrop = null"
    />

    <div class="inputWrapper">
      <template v-for="(item, index) in filterInputValue">
        <div
            class="imagePreview"
            :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.image))}"
        >
          <label
              :class="{hasImage: item.image}"
              class="wrapper"
              :for="`${uid}-${index}`"
          >
            <input
                class="input"
                :id="`${uid}-${index}`"
                type="file"
                @change="onFileChange($event, index)"
                accept="image/*"
            />
          </label>
          <button
              class="removeButton"
              @click="removeFile($event, item)"
          />
        </div>
      </template>
      <div
          v-if="inputValue && filterInputValue.length < 3"
          class="imagePreview"
      >
        <label
            class="wrapper"
            :for="`${uid}-last`"
        >
          <input
              class="input"
              :id="`${uid}-last`"
              type="file"
              @change="onFileChange($event)"
              accept="image/*"
          />
        </label>
      </div>
    </div>

    <div v-if="textInfo" class="textInfo">
      {{ textInfo }}
    </div>
    <div class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, toRaw, toRef} from 'vue'
import {useField} from 'vee-validate'
import {getUidNumber} from '@/utils/globalUidCounter'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import ImageCropper from "@/components/UI/ImageCropper.vue";
import {resizeImage} from "@/utils/resizeImage.js";

const uid = `ImageList-${getUidNumber()}`

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  textInfo: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  imagePreview: {
    type: String,
  },
  aspectRatio: {
    type: Number,
    default: 1,
  },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const state = reactive({
  filesToCrop: [],
  currentIndex: 0,
})

const filterInputValue = computed(() => {
  if(Array.isArray(inputValue.value)) {
    return inputValue.value.filter(item => !item.isDeleted)
  } else {
    return []
  }
})

async function onFileChange(e, index) {
  const newImage = await resizeImage({
    file: e.target.files[0],
    maxSize: 1080 * 4,
  })
  state.filesToCrop = [newImage]
  state.currentIndex = index
}

async function onCrop(rawFile) {
  const blob = await resizeImage({
    file: rawFile,
    maxSize: 1080,
  })
  const file = new File([blob], rawFile.name)

  const newValues = toRaw(inputValue.value)

  if (Number.isInteger(state.currentIndex)) {
    const toChange = newValues.at(state.currentIndex)
    toChange.file = file
    toChange.image = URL.createObjectURL(file)
    toChange.changed = true
  } else {
    newValues.push({
      file,
      image: URL.createObjectURL(file),
      changed: true,
    })
  }
  handleChange(newValues)
}

function removeFile(e, item) {
  const newValues = toRaw(inputValue.value)
  const toChange = newValues.find(i => i.image === item.image)
  toChange.changed = true
  toChange.isDeleted = true
  handleChange(newValues)
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  transition: opacity .3s ease;
  cursor: pointer;
  background-color: #222222;
  background-image: url("@/assets/icons/white_24_plus.svg");
  background-position: center center;
  background-repeat: no-repeat;
  height: 80px;
  width: 64px;
  border-radius: 8px;
  border: 1px solid #494949;
  display: flex;

  &:hover {
    opacity: .8;
  }

  &.hasImage {
    background: transparent;
    border: none;
  }
}

.imagePreview {
  position: relative;
  border-radius: 8px;
  width: 64px;
  background-size: cover;
  background-position: center center;
}

input {
  display: none;
}

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    margin-top: 4px;
    width: 100%;
    position: relative;
    display: flex;
    gap: 8px;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }
}

.removeButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url("@/assets/icons/closeRounded.svg") center center no-repeat;
  cursor: pointer;
}

</style>
