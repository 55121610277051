<template>
  <div class="profile_wrapper">
    <head-new
        :back-action="() => router.push({name: 'Home'})"
    />

    <div class="main_container pageWrapperOverflow">
      <profile-head style="margin-bottom: 20px"/>

      <BlockContacts
          :profile="profile"
      />

      <BlockSubscription />

      <BlockNiches
          :isEdit="true"
          :niches="profile.niches"
      />

      <BlockLookingFor
          :isEdit="true"
          :lookingFor="profile.looking_for"
          :is-active="false"
      />

      <BlockAchievements
          :isEdit="true"
          :achievements="profile.achievements"
      />

      <BlockProducts
          :isEdit="true"
          :products="profile.products"
      />

      <BlockEducations
          :isEdit="true"
          :education="profile.education"
      />

      <AppButton
          v-if="state.subscriptionStatus in {[subscriptionStatuses.active]: true}"
          variant="transparent"
          @click="router.push({name: 'SubscriptionControl'})"
          size="large"
          style="color: #A6A5A5; margin-left: auto; margin-right: auto"
      >
        Управлять подпиской
      </AppButton>

      <AppButton
          variant="transparent"
          @click="logOut"
          size="large"
          style="color: #FF303A; margin-left: auto; margin-right: auto"
      >
        <Icon :icon="require(`@/assets/icons/red_24_exit.svg`)"/>
        Выйти из аккаунта
      </AppButton>

    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import ProfileHead from "@/components/profile/ProfileHead.vue"
import store from '@/store/store'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {computed, reactive, ref, watch} from 'vue'
import {router} from '@/router/router'
import MenuItem from '@/components/UI/MenuItem.vue'
import BlockNiches from '@/components/BlockNiches.vue'
import BlockAchievements from '@/components/BlockAchievements.vue'
import BlockProducts from '@/components/BlockProducts.vue'
import BlockLookingFor from '@/components/BlockLookingFor.vue'
import BlockEducations from '@/components/BlockEducations.vue'
import {useShare} from '@/hooks/useShare'
import BlockCompleteProfile from '@/components/BlockCompleteProfile.vue'
import BlockContacts from '@/components/BlockContacts.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import BlockSubscription from "@/views/subscriptions/BlockSubscription.vue";
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'

const profile = store.state.user.profile


const progress = computed(() => {
  return store.getters["user/profileProgress"]
})

const {canShare, share} = useShare()

const state = reactive({
  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  state.subscriptionStatus = subscriptions.at(0)?.status ?? subscriptionStatuses.new
})

async function logOut() {
  await store.dispatch('user/logout')
}

async function submitShare() {
  const resolved = router.resolve({name: 'AnotherUsersProfile', params: {userId: profile.id}})
  const url = new URL(window.location.href)
  url.pathname = resolved.fullPath
  await share({
    title: `${profile.first_name} ${profile.last_name}`,
    description: profile.about_me,
    url: url.toString(),
  })
}

async function openSubscription() {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    returnName: 'Profile',
  }))
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}


</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.profile_wrapper {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100svh - $bottomMenuOffset);
  max-height: calc(100svh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 16px;
}

.menuItems {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > *:last-child {
    margin-top: 24px;
  }
}
</style>
