<template>
  <div class="page">
    <template v-if="(!state.showFinalResult || state.isFree) && state.currentQuestion">
      <head-new
          :title="`Задание ${state.questionIndex + 1}`"
          :isLoading="state.isLoading"
          :backAction="back"
      />

      <div class="main_container progress">
        <TestProgress
            :isLoading="state.isLoading"
            :barCount="state.lessonTest.studentTest.progress.total_questions"
            :completed="state.questionIndex"
        />
      </div>

      <div v-if="!state.isLoading" class="main_container">
        <div class="questionType">
          {{ questionTypesText[state.currentQuestion?.test_question_type] }}
        </div>
        <div class="questionText">
          <LessonContentHTML
              :data="{html: state.currentQuestion?.html}"
          />
        </div>

        <div class="questions">
          <TestAnswer
              v-for="answer in state.answers"
              :variant="state.currentQuestion.test_question_type"
              :answer="answer"
              :isSelected="answer.isSelected"
              :showsResult="state.currentQuestion?.showsResult"
              @click="toggleAnswer(answer)"
          />
        </div>

        <AppButton
            v-if="!state.currentQuestion?.showsResult"
            full-width
            size="large"
            @click="submitAnswers"
            :disabled="state.answers.filter(a => a.isSelected).length === 0"
        >
          Проверить
        </AppButton>
        <div v-else style="height: 48px"/>
      </div>

      <div class="space"/>

      <div v-if="state.currentQuestion" class="main_container buttons">
        <AppButton
            :disabled="!state.lessonTest?.test?.test_questions[state.questionIndex - 1]"
            variant="secondary"
            size="large"
            @click="state.questionIndex--"
        >
          <Icon :icon="require(`@/assets/icons/white_24_arrow.svg`)" rotate="180"/>
        </AppButton>

        <AppButton
            :disabled="!state.currentQuestion.showsResult"
            size="large"
            full-width
            @click="state.questionIndex++"
        >
          <div style="display: inline-flex; align-items: center;gap: 8px;">
            <div>Следующий вопрос</div>
            <Icon :icon="require(`@/assets/icons/white_24_arrow.svg`)"/>
          </div>
        </AppButton>
      </div>
    </template>
    <template v-else>
      <head-new
          :isLoading="state.isLoading"
          :backAction="back"
      />

      <template v-if="!state.isLoading">
        <div class="space"/>
        <div class="space"/>
        <div class="space"/>

        <div class="main_container final">
          <div class="finalIcon"/>
          <div class="finalText">
            Правильно решено {{ correctAnswers }} из {{ state.lessonTest.test.test_questions.length }}
          </div>
        </div>

        <div class="space"/>
        <div class="space"/>
        <div class="space"/>
        <div class="space"/>

        <div class="main_container buttons">
          <AppButton
              :disabled="!state.lessonTest?.test?.test_questions[state.questionIndex - 1]"
              variant="secondary"
              size="large"
              @click="state.questionIndex--"
          >
            <Icon :icon="require(`@/assets/icons/white_24_arrow.svg`)" rotate="180"/>
          </AppButton>

          <AppButton
              size="large"
              full-width
              @click="back"
          >
            Вернуться к курсу
          </AppButton>
        </div>

      </template>
    </template>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {computed, onBeforeMount, reactive, watch} from 'vue'
import {appAxios} from '@/axios'
import TestProgress from '@/components/BarProgress.vue'
import LessonContentHTML from '@/views/study/lessonContent/LessonContentHTML.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import {questionTypes, questionTypesText} from '@/configs/questionTypes'
import TestAnswer from '@/components/study/TestAnswer.vue'
import {router} from '@/router/router'
import ModalDialog from "@/components/UI/ModalDialog.vue";

const props = defineProps({
  userCourseId: {
    type: String,
  },
  moduleId: {
    type: String,
  },
  lessonId: {
    type: String,
  },
  testId: {
    type: String,
  },
})

const state = reactive({
  showFinalResult: false,
  isFree: false,
  isLoading: true,
  lessonTest: null,
  currentQuestion: null,
  questionIndex: -1,
  answers: [],
})

watch(() => state.questionIndex, async (newIndex, oldIndex) => await calcQuestion(newIndex, oldIndex))

const correctAnswers = computed(() => {
  let correctAnswers = 0

  if (!state.lessonTest || !state.lessonTest.studentTest) {
    return null
  }

  state.lessonTest.test.test_questions.forEach(q => {
    const every = q.answers.every(a => {
      if (a.is_correct) {
        return state.lessonTest.studentTest.student_answers.find(sa => sa.test_answer.id === a.id)
      } else {
        return !state.lessonTest.studentTest.student_answers.find(sa => sa.test_answer.id === a.id)
      }
    })
    if (every) {
      correctAnswers++
    }
  })

  return correctAnswers
})

async function calcQuestion(newIndex, oldIndex) {
  if (newIndex !== oldIndex) {
    state.currentQuestion = state.lessonTest.test.test_questions[newIndex]

    if (!state.currentQuestion) {

      if (!state.lessonTest.studentTest.finished_at) {
        state.showFinalResult = true
        state.isFree = true
        await appAxios.myTest.finish(state.lessonTest.studentTest.id)
      } else {
        state.isFree = true
      }
      return
    }

    state.currentQuestion.showsResult = false

    state.answers = state.currentQuestion.answers.map(a => {
      return {
        ...a,
        isSelected: false,
      }
    })

    let foundSome = false
    state.lessonTest.studentTest.student_answers.forEach(sa => {
      const found = state.answers.find(a => {
        return a.id === sa.test_answer.id
      })
      if (found) {
        foundSome = true
        found.isSelected = true
      }
    })

    if (state.isLoading && foundSome) {
      state.questionIndex = newIndex + 1
      await calcQuestion(newIndex + 1, newIndex)
      return
    }

    if (foundSome) {
      state.currentQuestion.showsResult = true
    }
  }
}

function toggleAnswer(answer) {
  if (state.currentQuestion?.showsResult) {
    return
  }
  if (state.currentQuestion.test_question_type === questionTypes.single_answer) {
    state.answers.forEach(a => a.isSelected = false)
  }
  answer.isSelected = !answer.isSelected
}

async function submitAnswers() {
  const results = state.answers.filter(a => a.isSelected)
  const res = await appAxios.myTest.update(state.lessonTest.studentTest.id, results.map(a => a.id))
  state.lessonTest.studentTest = res.data
  state.currentQuestion.showsResult = true
}

onBeforeMount(async () => {
  const res = await appAxios.myCourses.fetchOne(props.userCourseId)
  const resCourse = await appAxios.course.cacheOne(res.data.course_id)

  let lessons = null
  if (props.moduleId) {
    state.courseType = 'StudyModuleLesson'
    const module = resCourse.data.modules.find(module => module.id === Number(props.moduleId))
    const studentModule = res.data.student_modules.find(st => st.module_id === module.id)
    state.module = module
    lessons = module.lessons
    const lesson = lessons.find(l => l.id.toString() === props.lessonId)
    lessons = lessons.filter(l => l.lesson_type === lesson.lesson_type)

    studentModule.student_lessons.forEach(ml => {
      if (ml.lesson_id === lesson.id) {
        state.studentLesson = ml
      }
    })
  } else {
    state.courseType = 'StudyCourseLesson'
    lessons = resCourse.data.lessons
    const lesson = lessons.find(l => l.id.toString() === props.lessonId)
    lessons = lessons.filter(l => l.lesson_type === lesson.lesson_type)
    res.data.student_lessons.forEach(sl => {
      if (sl.lesson_id === lesson.id) {
        state.studentLesson = sl
      }
    })
  }
  const lesson = lessons.find(l => l.id.toString() === props.lessonId)
  const lessonNumber = lessons.indexOf(lesson) + 1
  state.lesson = lesson

  state.lessons = lessons
  state.lessonNumber = lessonNumber
  state.lessonsCount = lessons.length
  state.userCourse = res.data
  state.course = res.data.course

  if (!state.studentLesson) {
    const res = await appAxios.myLessons.start(state.lesson.id)
    state.studentLesson = res.data
  }

  const lessonTests = state.lesson.lesson_tests

  state.studentLesson.student_tests.forEach(studentTest => {
    const found = lessonTests.find(test => test.id === studentTest.lesson_test.id)
    found.studentTest = studentTest
  })

  state.lessonTests = lessonTests
  state.lessonTest = lessonTests.find(t => t.test.id.toString() === props.testId)

  if (!state.lessonTest.studentTest) {
    const res = await appAxios.myTest.start(state.lessonTest.id)
    state.lessonTest.studentTest = res.data
  }

  state.questionIndex = 0
  await calcQuestion(0, -1)

  state.isLoading = false
})


function back() {
  router.push({
    name: props.moduleId ? 'StudyModuleLesson' : 'StudyCourseLesson',
    params: {
      userCourseId: props.userCourseId,
      moduleId: props.moduleId,
      lessonId: props.lessonId,
    },
  })
}
</script>

<style lang="scss" scoped>

.page {
  min-height: calc(100vh - 114px);
  min-height: calc(100svh - 114px);
  display: flex;
  flex-direction: column;

  .space {
    flex-grow: 1;
  }
}

.progress {
  margin-top: 12px;
  margin-bottom: 24px;
}

.questionType {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #838181;
  margin-bottom: 12px;
}

.questionText {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  gap: 12px;
  margin-top: 36px;
}

.final {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .finalIcon {
    background-image: url("@/assets/icons/bigSuccess.svg");
    height: 112px;
    width: 112px;
  }

  .finalText {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #FFF;
  }
}

</style>
