<template>
  <div class="backdrop">
    <div
        class="logo"
        :style="{backgroundImage: base64ToCssURL(store.state.app.appIcons.logo128x58)}"
    />
  </div>
</template>

<script>
import store from '@/store/store.js'
import {base64ToCssURL} from '@/utils/urlToCssURL.js'

export default {
  name: "Logo",
  methods: {base64ToCssURL},
  computed: {
    store() {
      return store
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.backdrop {
  display: inline-flex;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 239px;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);
    background: rgb(207, 46, 46);
    opacity: 0.08;
    position: absolute;
    top: -119px;
    left: 0;
  }
  .logo {
    display: block;
    height: 58px;
    width: 128px;
    position: relative;
    background-repeat: no-repeat;
    //background: url("@/assets/images/logo/white_logo_leadpay_128_58.svg") no-repeat;
    background-size: contain;
  }
}

img {
  user-select: none;
}
</style>
