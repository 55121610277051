import moment from 'moment'
import {reactive} from 'vue'

const localStorageKey = 'imagesCacheLinks'

let state = {
  version: 2,
  // 'pathname': {
  //   url: '',
  //   expires: 0,
  // }
}

const images = reactive({})

export const imagesLinkCache = {

  store() {
    localStorage.setItem(localStorageKey, JSON.stringify(state))
  },

  clear() {
    localStorage.removeItem(localStorageKey)
  },

  restore() {
    const restored = JSON.parse(localStorage.getItem(localStorageKey))
    if(restored?.version !== state.version) {
      imagesLinkCache.clear()
      return
    }
    if (typeof restored !== 'object' || restored === null) {
      return
    }
    state = restored
  },

  retry(imgURL) {
    const loadingImage = require(`@/assets/skeleton.png`)
    if(imgURL === loadingImage) {
      return loadingImage
    }
    if (!images[imgURL]) {
      images[imgURL] = loadingImage
    }

    if(images[imgURL] === loadingImage) {
      void async function () {
        const img = new Image()
        img.src = imgURL

        try {
          await img.decode()
        } catch (e) {
          // cant be decoded
        }

        if(img.width) {
          images[imgURL] = imgURL
          return
        }

        const url = new URL(imgURL)

        if (state[url.pathname]) {
          const cache = state[url.pathname]
          if(imgURL !== cache.lastURL) {
            state[url.pathname] = {
              lastURL: cache.lastURL,
              url: cache.lastURL,
              expires: cache.expires,
            }
            imagesLinkCache.store()
            return imagesLinkCache.retry(cache.lastURL)
          } else {
            await fetch(imgURL, {cache: 'reload', mode: 'no-cors'})
            return imgURL
          }
        }
      }()
    }

    return images[imgURL]
  },

  cache(imgURL) {
    if (!imgURL) {
      return imgURL
    }

    try {
      const url = new URL(imgURL)
      const rawDate = url.searchParams.get('X-Amz-Date')
      const rawExpires = url.searchParams.get('X-Amz-Expires')

      if (!rawExpires || !rawDate) {
        return imagesLinkCache.retry(imgURL)
      }

      if (state[url.pathname]) {
        const now = Date.now()
        const cache = state[url.pathname]
        if (now < cache.expires) {
          if(imgURL !== cache.lastURL) {
            state[url.pathname] = {
              lastURL: imgURL,
              url: cache.url,
              expires: cache.expires,
            }
            imagesLinkCache.store()
          }
          return imagesLinkCache.retry(cache.url)
        }
      }

      const day = moment(rawDate, 'YYYYMMDD-hhmmss')
      day.add(Number(rawExpires) / 2, 'seconds')
      const time = new Date(day.format())
      const expires = time.getTime()

      state[url.pathname] = {
        lastURL: imgURL,
        url: imgURL,
        expires,
      }
      imagesLinkCache.store()

      return imagesLinkCache.retry(imgURL)
    } catch (e) {
      return imagesLinkCache.retry(imgURL)
    }
  },
}
