<template>
  <Skeleton
      v-if="isLoading"
      class="noShrink"
      :class="{[size]: size, [round]: round,}"
      :width="sizes[size].width"
      :height="sizes[size].height"
      :radius="radius ? `${radius}` : sizes[size].radius"
  />
  <div
      v-else
      class="avatar noShrink"
      :class="{
        [size]: size,
        warning,
      }"
      :style="{
        borderRadius: radius ? `${radius}px` : null,
        backgroundImage: image ? urlToCssURL(imagesLinkCache.cache(image)): null,
      }"
  />
</template>

<script setup>
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'

const sizes = {
  nano: {
    height: '28',
    width: '28',
    radius: '20',
  },
  mini: {
    height: '32',
    width: '32',
    radius: '16',
  },
  tiny: {
    height: '40',
    width: '40',
    radius: '20',
  },
  little: {
    height: '48',
    width: '48',
    radius: '8',
  },
  small: {
    height: '56',
    width: '56',
    radius: '28',
  },
  medium: {
    height: '64',
    width: '64',
    radius: '8',
  },
  large: {
    height: '106',
    width: '106',
    radius: '16',
  },
}

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String, // small, medium, large
    default: 'medium',
  },
  image: {
    type: String,
    default: '',
  },
  round: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: String,
    default: '',
  },
  warning: {
    type: Boolean,
    default: false,
  },
})

</script>

<style lang="scss" scoped>

.noShrink {
  flex-shrink: 0;
}

.avatar {
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  position: relative;

  &.round.round {
    border-bottom: 128px;
  }

  &.nano {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 28px;
    width: 28px;
    border-radius: 14px;
  }

  &.mini {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }

  &.tiny {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  &.little {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 48px;
    width: 48px;
    border-radius: 8px;
  }

  &.small {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 56px;
    width: 56px;
    border-radius: 28px;
  }

  &.medium {
    background-image: url("@/assets/images/profile/emptyAvatarSmall.svg");
    height: 64px;
    width: 64px;
    border-radius: 8px;
  }

  &.large {
    background-image: url("@/assets/images/profile/emptyAvatarMedium.svg");
    height: 106px;
    width: 106px;
    border-radius: 16px;
  }
}

</style>
