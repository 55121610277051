<template>
  <div v-if="!state.currentStepIndex" class="pageWrapper">
    <head-new
        :back-action="state.currentStepIndex > 1 ? back : undefined"
        title="Регистрация"
    />
    <div
        class="main_container pageWrapperOverflow"
        :class="{tipHidden: state.tipHidden}"
    >
      <Typography
          variant="h2"
          style="margin-top: 24px;margin-bottom: 8px"
      >
        Поздравляем, ты успешно завершил первые шаги!
      </Typography>

      <!--      <Typography-->
      <!--          variant="v14-400"-->
      <!--          style="margin-bottom: 8px"-->
      <!--          color="#A6A5A5"-->
      <!--      >-->
      <!--        Осталось всего 3 шага, чтобы открыть доступ ко всем функциям приложения-->
      <!--      </Typography>-->

      <Card>
        <Typography
            variant="v14-400"
            style="margin-bottom: 16px"
            color="#A6A5A5"
        >
          Осталось всего 3 шага, чтобы открыть доступ ко всем функциям приложения
        </Typography>
        <BarProgress
            :completed="2"
            :barCount="5"
        />
      </Card>

      <Card style="margin-top: 8px">
        <Typography variant="v16-700" style="margin-bottom: 16px">
          Доступно сейчас:
        </Typography>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_book.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Обучающие курсы
            </Typography>
            — учись у экспертов
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_store.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Продукты на ярмарке
            </Typography>
            — ищи лучшие предложения
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_folder.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Мероприятия
            </Typography>
            — следи за актуальными событиями
          </Typography>
        </div>
      </Card>

      <Card style="margin-top: 8px; margin-bottom: 8px">
        <Typography variant="v16-700" style="margin-bottom: 16px">
          Будет доступно после заполнения профиля:
        </Typography>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_browsing.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Нетворкинг
            </Typography>
            — создавай связи с 1000+ экспертами
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_businessCards.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Визитница
            </Typography>
            — управляй контактами, мэтчами и интересами
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_store.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Полный доступ в ярмарку
            </Typography>
            — размещай и запрашивай продукты
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_folder.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Рекомендация мероприятий
            </Typography>
            — предлагай свои события
          </Typography>
        </div>

        <div class="infoLine">
          <div class="redIcon">
            <Icon
                :icon="require(`@/assets/icons/white_24_chat.svg`)"
                height="24"
                width="24"
            />
          </div>
          <Typography base="span" variant="v14-400" color="#A6A5A5">
            <Typography base="span" variant="v14-700" color="#FFF">
              Безграничные возможности
            </Typography>
            для общения и роста
          </Typography>
        </div>
      </Card>

      <div style="flex-grow: 1 "/>

      <div
          style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
      >
        <AppButton
            style="margin-top: 16px; margin-bottom: 12px"
            size="large"
            full-width
            @click="nextStep"
        >
          Продолжить заполнение профиля
        </AppButton>

        <AppButton
            variant="secondary"
            style="margin-top: 8px; margin-bottom: 24px"
            size="large"
            full-width
            @click="skip"
        >
          Перейти к приложению
        </AppButton>
      </div>
    </div>
  </div>
  <div v-if="state.currentStepIndex" class="pageWrapper">
    <head-new
        :back-action="state.currentStepIndex > 1 ? back : undefined"
        title="Регистрация"
    />
    <div class="main_container pageWrapperOverflow">

      <div class="steps">
        <Typography variant="body2" color="#A6A5A5">
          Шаг {{ Math.min(state.currentStepIndex, maxSteps) }} из {{ maxSteps }}
        </Typography>
        <div class="logo"/>
      </div>

      <BarProgress
          :completed="state.currentStepIndex"
          :barCount="maxSteps"
          style="margin-bottom: 24px"
      />

      <component
          :is="onwardsSteps[state.currentStepIndex]"
          :nextStep="nextStep"
          :createUserStepIfNotExist="() => null"
          style="flex-grow: 1;  display: flex;  flex-direction: column;"
      />
    </div>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import Typography from '@/components/UI/Typography.vue'
import BarProgress from '@/components/BarProgress.vue'
import {onBeforeMount, onBeforeUnmount, reactive, ref} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import {router} from '@/router/router.js'
import {onwardsSteps} from '@/views/profile/onwards/onwardsSteps.js'
import store from "@/store/store.js";

const bodyRef = ref(window.document.body)

const state = reactive({
  currentStepIndex: Number(localStorage.getItem('onboardingContinuation')),
  tipHidden: false,
})

const maxSteps = 3

function back() {
  state.currentStepIndex--
}

async function nextStep() {
  console.log(state.currentStepIndex, maxSteps + 1)
  if (state.currentStepIndex === maxSteps + 1) {
    return skip()
  }
  state.currentStepIndex++
  localStorage.setItem('onboardingContinuation', state.currentStepIndex)
}

function skip() {
  localStorage.removeItem('onboardingContinuation')
  router.push({name: 'Home'})
}


function onFocus(event) {
  if (event.target.nodeName === "TEXTAREA" || event.target.nodeName === "INPUT") {
    const el = document.getElementById('bottom-support')
    if(el) {
      state.tipHidden = true
      el.style.visibility = "hidden"
    }
  }
}

function onBlur(event) {
  if (event.target.nodeName === "TEXTAREA" || event.target.nodeName === "INPUT") {
    const el = document.getElementById('bottom-support')
    if(el) {
      state.tipHidden = false
      el.style.visibility = "visible"
    }
  }
}

// onBeforeMount(async () => {
//   bodyRef.value.addEventListener("focus", onFocus, true)
//   bodyRef.value.addEventListener("blur", onBlur, true)
// })
//
// onBeforeUnmount(() => {
//   bodyRef.value.removeEventListener("focus", onFocus, true)
//   bodyRef.value.removeEventListener("blur", onBlur, true)
// })

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100svh);
  max-height: calc(100svh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  padding-bottom: 24px;
  margin-bottom: 140px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.tipHidden {
    margin-bottom: 0;
  }

  & > * {
    flex-shrink: 0;
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 12px;

  .logo {
    width: 96px;
    height: 24px;
    position: relative;
    background: url("@/assets/images/light_logo.svg") no-repeat;
    background-size: contain;
  }
}

.infoLine {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 0 0;
}

.redIcon {
  height: 36px;
  width: 36px;
  padding: 6px;
  background: #CF2E2E;
  border-radius: 8px;
}


</style>
