<template>
  <div class="pageWrapper">
    <HeadNew
        :back-action="() => router.push({name: 'Collection', params: { collectionId: props.collectionId}})"
        :is-loading="state.isLoading"
        :title="state.collection?.lesson.title"
    />
    <div
        class=" pageWrapperOverflow"
    >
      <div
          v-if="!state.isLoading"
          class="main_container content"
          :class="{video: 'video' === item.content_type}"
          v-for="(item, i) in state.lesson.lesson_items"
      >
        <component
            :is="lessonContent[item.content_type]"
            :data="item"
        />
      </div>

      <div class="main_container content" v-if="!state.isLoading">
        <template v-if="state.lesson.lesson_materials.length">
          <Card>
            <div class="filesTitle">
              Материалы урока
              <div class="filesContainer">
                <div
                    v-for="material in state.lesson.lesson_materials"
                    class="fileLink"
                    @click="() => openInNewTab(material.link ?? material.file)"
                >
                  {{ material.title }}
                  <div class="icon"/>
                </div>
              </div>
            </div>
          </Card>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, reactive} from 'vue'
import HeadNew from '@/components/Head.vue'
import {router} from '@/router/router.js'
import Card from '@/components/UI/Card.vue'
import {lessonContent} from '@/views/study/lessonContent/LessonContent.js'
import {openInNewTab} from '@/utils/openInNewTab.js'
import {appAxios} from '@/axios.js'

const props = defineProps({
  collectionId: {
    type: String,
  }
})

const state = reactive({
  isLoading: true,
  collection: null,
  lesson: null,
})

onBeforeMount(async () => {
  state.isLoading = true

  const {response, axiosResponse} = await appAxios.collections.getById(props.collectionId)
  let res = response
  let axiosRes = axiosResponse

  state.collection = res.data
  state.lesson = state.collection.lesson

  axiosRes.then(async r => {
    state.collection = res.data
    state.lesson = state.collection.lesson
  })

  state.isLoading = false
})

function handleTimecodes(e) {
  let target = e.target
  if (target.tagName !== 'CODE') {
    if (target.parentNode.tagName === 'CODE') {
      target = e.target.parentNode
    } else {
      return
    }
  }

  const timeString = target.querySelector('strong')

  let text = timeString.innerText

  const arr = text.replaceAll(' ', '').split(':')

  let seconds = 0
  let minutes = 0
  let hours = 0

  if (arr.length === 1) {
    seconds = Number(arr[0])
  }
  if (arr.length === 2) {
    seconds = Number(arr[1])
    minutes = Number(arr[0])
  }
  if (arr.length === 3) {
    seconds = Number(arr[2])
    minutes = Number(arr[1])
    hours = Number(arr[0])
  }

  const time = seconds + minutes * 60 + hours * 60 * 60

  if (!time) {
    // todo: handle error
    console.log('err')
    return
  }

  let content = target.closest(".content")
  while (content) {
    content = content.previousSibling
    if (content.classList.contains('video')) {
      break
    }
  }
  if (!content) {
    return
  }

  const video = content.querySelector('video') ?? content.querySelector('.video')
  const videoWrapper = content.querySelector('.videoWrapper')

  if (videoWrapper && videoWrapper.dataset.uid) {
    const player = window.kinescopePlayers[videoWrapper.dataset.uid]
    player.play()
    player.seekTo(time)
  } else {
    video.currentTime = time
    video.play()
  }
}

onMounted(() => {
  addEventListener("click", handleTimecodes)
})

onUnmounted(() => {
  removeEventListener("click", handleTimecodes)
})


</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 80px);
  min-height: calc(100svh - 80px);
  max-height: calc(100vh - 80px);
  max-height: calc(100svh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
}


.content {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.taskText {
  font-size: 12px;
  font-weight: 500;
  color: #838181;
  margin-bottom: 6px;
}

.taskInfo {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 18px;
}

.filesTitle {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 700;
  color: #fff;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 8px;
}

.fileLink {
  transition: background-color ease .3s, border-color ease .3s, color ease .3s;
  background: #2F2F2F;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  color: #fff;

  &:hover {
    background: #393939;
  }

  &:active {
    background: #2C2B2B;
  }

  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background: url("@/assets/icons/white_24_arrowDiagonal.svg") no-repeat;
  }
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-flow: column;
  gap: 12px;
}

</style>
