<template>
  <div class="baseLayout">
    <component
        :is="{...state.layout}"
    >
      <slot/>
    </component>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
import HomeLayout from '@/layouts/HomeLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import {onBeforeMount, reactive} from 'vue'
import EmptyHomeLayout from "@/layouts/EmptyHomeLayout.vue"

const route = useRoute()

const layouts = {
  EmptyHomeLayout,
  HomeLayout,
  EmptyLayout,
}

const state = reactive({
  layout: EmptyLayout,
})

onBeforeMount(() => {
  state.layout = layouts[route.meta.layout]
  if (!state.layout) {
    state.layout = EmptyLayout
  }
})

</script>

<style lang="scss" scoped>

.baseLayout {
  // test
  //min-height: calc(100vh - 80px);
  //min-height: calc(100svh - 80px);
  //max-height: calc(100vh - 80px);
  //max-height: calc(100svh - 80px);

  //max-height: 100svh;
  //min-height: 100svh;

  margin-top: var(--document-offset-top);

  max-height: var(--document-height);
  min-height: var(--document-height);

  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
